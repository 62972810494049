<template>
    <div class="top_promotion_slider top_head_offer_bar top_side promo_notification">
        <div class="notificationCarousel pink d-flex align-items-center justify-content-between w-100">
            <div class="d-flex align-items-center justify-content-between make-text-center">
                <div class="notification">
                    <div class="text">
                        <p class="d-inline-block align-middle notfication_text">Special lifetime upgrade offer to the Agency plan - Expires in</p>
                        <Countdown class="mr-2" deadline="15 November 2019, 11:59 GMT"></Countdown>
                        <button style="background:#ff9300; color:#fff; margin:0 auto; margin-left:40px !important; font-weight:bold !important;"
                                class="ml-4 btn btn_white large_btn ml-auto"
                                @click.prevent="$router.push({name:'upgrade_lifetime_addon'})">
                            Click here to get access
                        </button>
                    </div>

                </div>
                <button type="button" class="close close_slider"
                        @click.prevent="" style="font-size:26px !important; color:#fff; right:12px;">&times;
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Countdown from 'vuejs-countdown'

export default {
  data () {
    return {
      is_notification_hide: false
    }
  },
  components: {
    Countdown
  },
  methods: {
    hidePromotion () {
      this.is_notification_hide = true
    }
  }
}
</script>
