<template>
    <div :class="this.css_class" class="background_img"
         :style="{ width: this.size + 'px', height: this.size + 'px', background:'url('+ this.url +')' }"></div>
</template>

<script>
export default {
  data () {
    return {}
  },

  props: {
    size: {
      type: Number,
      default: 30
    },
    css_class: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: '/img/profile_default.jpg'
    }
  },
  mounted () {

  },

  methods: {}
}
</script>
