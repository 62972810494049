<template>
  <span class="replug-frill-widget change_logs">
    <i v-tooltip.left-middle="'Product Updates'" class="fal fa-bullhorn notification_icon"></i>
  </span>
</template>

<script lang="ts">
export default ({
  name: 'FrillWidget',
  data: () => ({
    widget: undefined,
    config: undefined,
  }),
  mounted() {
    this.setupFrillWidget();
  },
  beforeUnmount() {
    if (this.widget) {
      this.widget.destroy();
      this.widget = undefined;
    }
    if (this.config) {
      window.Frill_Config = window.Frill_Config.filter((c) => c !== this.config);
    }
  },
  methods: {
    setupFrillWidget() {
      this.config = {
        key: 'e2b256dc-1820-487d-ac8b-51e9a2cfc71c', // <-- Add Widget key here
        callbacks: {
          onReady: (frillWidget) => {
            this.widget = frillWidget;
          },
        },
      };
      window.Frill_Config = window.Frill_Config || [];
      window.Frill_Config.push(this.config);
      if ('Frill' in window) {
        this.widget = window.Frill.widget(this.config);
      }
    },
  },
});
</script>
