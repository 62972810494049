<template>

    <div class="pricing__box">

        <div class="inner  clear">

            <!--<div class="price_box_3 col-md-3 current_plan"  >
                <div class="inner">
                    <h3 class="package">Free</h3>
                    <h2 class="price _orange ">$0</h2>

                    <p class="duration">Forever</p>

                    <ul class="list_detail">
                        <li><span>1000</span> views per month</li>
                        <li><span>3</span> Branded CTAs
                        <li><span>20</span> Replug Links</li>
                        <li><span><i class="fal fa-times"></i></span> Team Members</li>
                        <li><span><i class="fal fa-times"></i></span> API</li>
                    </ul>

                    <a href="" class="btn orange  pricing_btn current_plan_btn">Current Plan</a>

                </div>
            </div>-->

            <div class="price_box_3 col-md-4"  :class="{'current_plan': ['replug-professional', 'replug-personal', 'replug-agency'].includes(getSubscription.fastspring_name)}">
                <div class="inner">
                    <h3 class="package">Basic</h3>
                    <h2 class="price _orange ">$9</h2>

                    <p class="duration">monthly</p>

                    <ul class="list_detail">
                        <li><span class="bold">5,000</span>  clicks per month</li>
                        <li><span class="bold">1</span> Brand</li>
                        <li><span class="bold">5</span> Campaigns</li>
                        <li><span class="bold">1</span> Retargeting Pixel</li>
                        <li><span class="bold">Unlimited</span> {{ getSiteDetails.agency_name }} Links</li>
                        <li class="line_strike">Custom Domains</li>
                        <li class="line_strike">Team Members</li>
                        <li class="line_strike">Remove {{ getSiteDetails.agency_name }} Branding</li>

                        <li class="line_strike">Dedicated Support</li>
                        <li class="line_strike">API access</li>
                        <li class="line_strike">Onboarding & call support</li>
                    </ul>

                    <!--<a class="btn pricing_btn orange">Upgrade With Button</a>-->
                    <template v-if="getSubscription.fastspring_name=='replug-personal'">
                        <a class="btn pricing_btn current_plan_btn">Current Plan</a>
                    </template>
                    <template v-else-if="showTrialUpgrade()">
                        <template v-if="getProfile.fastspring_account_id">
                            <a @click.prevent="upgradePlan('replug-personal')" class="btn pricing_btn orange">Upgrade</a>
                        </template>
                        <template v-show="!getProfile.fastspring_account_id">
                            <a href="https://replugio.onfastspring.com/replug-personal"  @click.prevent="checkoutPlan('replug-personal')" class="btn pricing_btn orange">Upgrade</a>
                        </template>

                    </template>

                </div>
            </div>

            <div class="most_popular price_box_3 col-md-4" :class="{'current_plan': ['replug-professional', 'replug-agency'].includes(getSubscription.fastspring_name)}">
                <div class="popular_bar">
                    <p>Most Popular</p>
                </div>
                <div class="clearfix"></div>
                <div class="inner">
                    <h3 class="package">Pro</h3>
                    <h2 class="price _green ">$49</h2>

                    <p class="duration">monthly</p>

                    <ul class="list_detail">
                        <li><span class="bold">50,000</span>  clicks per month</li>
                        <li><span class="bold">5</span> Brand</li>
                        <li><span class="bold">Unlimited</span> Campaigns</li>
                        <li><span class="bold">Unlimited</span> Retargeting Pixel</li>
                        <li><span class="bold">Unlimited</span> {{ getSiteDetails.agency_name }} Links</li>
                        <li><span class="bold">5</span> Custom Domains</li>
                        <li><span class="bold">3</span> Team Members</li>
                        <li>Remove {{ getSiteDetails.agency_name }} Branding</li>

                        <li class="line_strike">Dedicated Support</li>
                        <li class="line_strike">API access</li>
                        <li class="line_strike">Onboarding & call support</li>
                    </ul>

                    <div v-if="getSubscription.fastspring_name=='replug-professional'">
                        <a javascript=":;" class="btn pricing_btn current_plan_btn">Current Plan</a>
                    </div>
                    <template v-else-if="showUpgrade()">
                        <template v-if="getProfile.fastspring_account_id">
                            <a @click.prevent="upgradePlan('replug-professional')" class="btn pricing_btn green">Upgrade</a>
                        </template>
                        <template v-show="!getProfile.fastspring_account_id">
                            <a  @click.prevent="checkoutPlan('replug-professional')" href="https://replugio.onfastspring.com/replug-professional" class="btn pricing_btn green">Upgrade</a>
                        </template>
                    </template>

                </div>
            </div>

            <div class="price_box_3 col-md-4" :class="{'current_plan': getSubscription.fastspring_name=='replug-agency'}">
                <div class="inner">
                    <h3 class="package">Agency</h3>
                    <h2 class="price _purple ">$79</h2>

                    <p class="duration">monthly</p>

                    <ul class="list_detail">
                        <li><span class="bold">200,000</span>  clicks per month</li>
                        <li><span class="bold">10</span> Brand</li>
                        <li><span class="bold">Unlimited</span> Campaigns</li>
                        <li><span class="bold">Unlimited</span> Retargeting Pixel</li>
                        <li><span class="bold">Unlimited</span> {{ getSiteDetails.agency_name }} Links</li>
                        <li><span class="bold">10</span> Custom Domains</li>
                        <li><span class="bold">10</span> Team Members</li>
                        <li>Remove {{ getSiteDetails.agency_name }} Branding</li>

                        <li class="">Dedicated Support</li>
                        <li class="">API access</li>
                        <li class="">Onboarding & call support</li>
                    </ul>
                    <template v-if="getSubscription.fastspring_name=='replug-agency'">
                        <a href="" class="btn  pricing_btn current_plan_btn">Current Plan</a>
                    </template>
                    <template v-else>
                        <template v-if="getProfile.fastspring_account_id">
                            <a @click.prevent="upgradePlan('replug-agency')" class="btn pricing_btn purple">Upgrade</a>
                        </template>
                        <template v-show="!getProfile.fastspring_account_id">
                            <a  @click.prevent="checkoutPlan('replug-agency')" href="https://replugio.onfastspring.com/replug-agency" class="btn pricing_btn purple">Upgrade</a>
                        </template>
                    </template>

                </div>
            </div>

        </div>
    </div>

</template>
<script>
import { mapGetters } from 'vuex'

export default ({
  methods: {
    showTrialUpgrade(morePlans = []){
      return [...morePlans, ...['trial', 'free', 'trial-personal', 'trial-professional', 'trial-agency']].includes(this.getSubscription.fastspring_name)
    },
    showUpgrade(){
      return this.showTrialUpgrade(['replug-personal'])
    }
  },
  created () {},
  computed: {
    ...mapGetters(['getSubscription', 'getProfile'])
  }
})
</script>
