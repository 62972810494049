<template>
  <div>
    <upgrade-subscription-modal :self="this"></upgrade-subscription-modal>
    <upgrade-ltd-subscription-modal :self="this"></upgrade-ltd-subscription-modal>
    <!--<apply-utm-modal></apply-utm-modal>-->
    <announcements-top-banner
      :text="`White-Label Replug as your own in-house URL shortener tool to establish brand authority in the eyes of your clients.`"
      :bgColor="'#ECF6FF'"
      :color="'#1F2937'"
      :hrefText="'Learn more'"
      :hrefColor="'#0075C1'"
      :hrefLink="'https://updates.replug.io/announcements/rebrand-with-replugs-white-label-feature'"
      :type="'announcements'"
      :iconColor="'#0075C1'"
      :emoji="' 🎉 '"
      :badgeText="'New'"
      v-if="!getSiteDetails.isWhiteLabel && getProfile && !getProfile.new_announcements"
    />

    <ui-top-banner
      :text="`Your subscription is past due, We were unable to charge your credit card. We'll reattempt on ${computeDate(getProfile.next_retry_date)}. Please update your payment information.`"
      :bgColor="'#fa6868'"
      :color="'#ffffff'"
      :hrefText="''"
      :hrefColor="'#ffea77'"
      :hrefLink="'/settings/billing'"
      :fontSize="'0.9rem'"
      :type="'trial'"
      v-if="getProfile.subscription_state == 'past_due' && !getSiteDetails.isWhiteLabel"
    />

    <ui-top-banner
      :text="`Your add-on subscription is past due, We were unable to charge your credit card. We'll reattempt on ${computeDate(getProfile.addon_subscription_status.addon_next_retry_date)}. Please update your payment information.`"
      :bgColor="'#fcd34d'"
      :color="'black'"
      :hrefText="''"
      :hrefColor="'#ea580c'"
      :hrefLink="'/settings/billing'"
      :fontSize="'0.9rem'"
      :type="'trial'"
      v-if="getProfile.addon_subscription_status && getProfile.addon_subscription_status.addon_subscription_state == 'past_due' && !getSiteDetails.isWhiteLabel"
    />

    <ui-top-banner
      :text="`Your account subscription has been paused. Please restart your subscription to continue using ${getSiteDetails.agency_name} platform.`"
      :bgColor="'#fcd34d'"
      :color="'black'"
      hrefText=""
      :hrefColor="'#ea580c'"
      iconColor="#fcd34d"
      hrefLink=""
      :fontSize="'0.9rem'"
      :type="'trial'"
      v-if="getProfile.subscription_paused && !getSiteDetails.isWhiteLabel"
    />

    <ui-top-banner
      :text="subscriptionEffectiveCancelDate"
      :bgColor="'#fa6868'"
      :color="'#ffffff'"
      :hrefText="'Renew Subscription.'"
      :hrefColor="'#ffea77'"
      :hrefLink="'upgrade-subscription-modal'"
      :fontSize="'0.9rem'"
      :type="'trial'"
      v-if="!!getProfile.cancellation_effective_at && !getSiteDetails.isWhiteLabel"
    />

    <!-- trialText  (Get 70% OFF On Annual Plans This Black Friday -  /special-offer -->
    <ui-top-banner
      :text="trialText"
      :bgColor="'#ffefd1'"
      :color="'#53555b'"
      :hrefText="' Upgrade Plan!'"
      :hrefColor="'#53555b'"
      :hrefLink="'upgrade-subscription-modal'"
      :fontSize="'0.9rem'"
      :iconColor="'#000000'"
      :type="'past_due'"
      v-if="!getSiteDetails.isWhiteLabel && isPastDue && fastspring_name('trial') && getProfile.policy.can_view_billing && !['special-offer','upgrade-plan'].includes($route.name)"
    />

    <div id="referralModalFeature" class="modal normal_modal slide_modal fade" role="dialog"
         v-if="!getSiteDetails.isWhiteLabel">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Unlock Your Features!</h4>
            <button type="button" class="close" data-dismiss="modal">
              <img src="/img/cancel.png" alt="">
            </button>
          </div>
          <div class="modal-body">

            <div class="check_list">
              <p class="list_heading">Refer {{ getSiteDetails.agency_name }}.io to Unlock Powerful Features</p>

              <div class="list_inner">
                <ul>
                  <li>
                    <p class="checkbox">
                      <input type="checkbox" disabled="disabled"
                             :checked="getProfile.referral_level >= 1">
                      <label>Widget Customization</label>
                    </p>
                    <p class="text">
                      <span>1st</span>
                      <span>Referral</span>
                    </p>
                  </li>
                  <li>
                    <p class="checkbox">
                      <input type="checkbox" disabled="disabled"
                             :checked="getProfile.referral_level >= 2">
                      <label>Remove {{ getSiteDetails.agency_name }} Branding</label>
                    </p>
                    <p class="text">
                      <span>2nd</span>
                      <span>Referral</span>
                    </p>
                  </li>
                  <li>
                    <p class="checkbox">
                      <input type="checkbox" disabled="disabled"
                             :checked="getProfile.referral_level >= 3">
                      <label>Form CTA &amp; Integrations Unlock</label>
                    </p>
                    <p class="text">
                      <span>3rd</span>
                      <span>Referral</span>
                    </p>
                  </li>
                  <li>
                    <p class="checkbox">
                      <input type="checkbox" disabled="disabled"
                             :checked="getProfile.referral_level >= 5">
                      <label>Retargeting Pixel</label>
                    </p>
                    <p class="text">
                      <span>5th</span>
                      <span>Referral</span>
                    </p>
                  </li>
                  <li>
                    <p class="checkbox">
                      <input type="checkbox" disabled="disabled"
                             :checked="getProfile.referral_level >= 7">
                      <label>Custom Domains</label>
                    </p>
                    <p class="text">
                      <span>7th</span>
                      <span>Referral</span>
                    </p>
                  </li>
                  <li style="display: none;" v-if="false">
                    <p class="checkbox">
                      <input type="checkbox" disabled="disabled"
                             :checked="getProfile.referral_level >= 10">
                      <label>$15 Credit</label>
                    </p>
                    <p class="text">
                      <span>10th</span>
                      <span>Referral</span>
                    </p>
                  </li>

                  <!--<li>
                      <p class="checkbox">
                          <input type="checkbox" disabled="disabled"  :checked="getProfile.referral_level >= 18">
                          <label>Receive $15 Credit after every 3rd referral.</label>
                      </p>
                  </li>-->
                </ul>
              </div>
            </div>

            <div class="check_list">

              <p class="list_heading">Invite friends and colleagues:</p>

              <div class="list_inner">
                <ul>
                  <li class="referral_link">
                    <p class="link">
                      <a v-clipboard:copy="referralLink()"
                         v-clipboard:success="onCopy"
                         v-clipboard:error="onError"
                         href="javascript:;"> {{ referralLink() }}</a>
                    </p>
                    <p class="text">
                      <i class="far fa-link" v-clipboard:copy="referralLink()"
                         v-clipboard:success="onCopy"
                         v-clipboard:error="onError"></i>
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="check_list text-center">

              <p class="list_heading">
                Share your referral link and get rewarded for each friend you invite when they
                sign-up.</p>

              <div class="circle_social">
                <social-sharing style="display: inline-block;"
                                :url="'https://replug.io?ref='+ getProfile.referral_id" inline-template>
                  <div>
                    <network network="facebook">
                      <i class="fab fa-fw fa-facebook fb_bg"></i>
                    </network>

                    <network network="linkedin">
                      <i class="fab fa-fw fa-linkedin lin_bg"></i>
                    </network>
                  </div>

                </social-sharing>
                <a class="twitter-btn" target="_blank"
                   :href="'https://twitter.com/home?status=Check%20out%20%40replugio%20to%20get%20more%20traffic%20and%20leads%20by%20sharing%20other%20people%27s%20content.%20%23marketing%20https%3A//replug.io?ref='+ getProfile.referral_id">
                  <i class="fab fa-twitter tw_bg"></i>
                </a>
              </div>

            </div>

          </div>
        </div>

      </div>
    </div>


    <promotion-header-notification v-if="isBruceCampaign() && false"></promotion-header-notification>
    <div v-if="!['special-offer','upgrade-plan'].includes($route.name)" class="navigation_component"
         :class="{'activeTourPositionFix' : getGuidedTour.step2 || getGuidedTour.step3}">
      <!--container-->
      <div class="container_wide">
        <div class="navigation_component_inner d-flex align-items-center">
          <div class="left_side" :class="{'activeTour' : getGuidedTour.step2}">
            <guided-tour v-if="getGuidedTour.step2"></guided-tour>
            <ul class="d-flex align-items-center">

              <li class="logo">
                <a class="logo p-0 cursor_pointer" @click.prevent="home()">
                  <img :src="getSiteDetails.header_logo" alt="">
                </a>
              </li>

              <li class="simple_title">
                <router-link :to="{'name': 'dashboard'}">
                  Dashboard

                  <!--                  <span :class="{'animate' : $route.name === 'dashboard' }"-->
                  <!--                        class="line_animate_h  "></span>-->
                </router-link>
              </li>
              <li @mouseover="onOver('manage')" @mouseleave="onLeave('manage')" class="m-0 manage-dropdown">

                <b-dropdown ref="create_dropdown_menu"
                            id="dropdown-left"
                            class="create_dropdown dropdown-menu-left default_style_dropdown"
                            style="padding: 20px 10px !important;"
                            :no-caret="true">
                  <div class="dropdown_header d-flex align-items-center menu_item" slot="button-content"
                       style="padding: 0 5px !important;">
                    <span class="text">Manage</span>

                    <span class="arrow_icon ml-auto">
                                        <i class="fal fa-angle-down ml-2"></i>
                                 </span>

                  </div>
                  <transition name="fade">
                    <ul class="inner"
                        style="transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;">

                      <ul class="left_ul clear">
                        <li @click="$refs.create_dropdown_menu.hide(true)"
                            :class="{'active_menu' : $route.name === 'brands' }">
                          <router-link :to="{'name': 'brands'}">
                            <div class="d-flex justify_left">
                              <img class="tag" src="@/assets/img/header/price-tag.png">
                              <h3>
                                Brands
                                <span :class="{'animate' : $route.name === 'brands' }">
                              Your complete link management solution.
                            </span>
                              </h3>
                            </div>
                          </router-link>
                        </li>

                        <li @click="$refs.create_dropdown_menu.hide(true)"
                            :class="{'active_menu' : $route.name === 'campaigns' }">
                          <router-link :to="{'name': 'campaigns'}">
                            <div class="d-flex justify_left">
                              <img class="tag" src="@/assets/img/header/goal.png">
                              <h3>
                                Campaigns
                                <span :class="{'animate' : $route.name === 'campaigns' }">
                              A campaign allows you to create widgets for your brand advertisement.
                            </span>
                              </h3>
                            </div>
                          </router-link>
                        </li>

                        <li @click="$refs.create_dropdown_menu.hide(true)"
                            :class="{'active_menu' : $route.name === 'links' }">
                          <router-link :to="{'name': 'links'}">
                            <div class="d-flex justify_left">
                              <img class="tag" src="@/assets/img/header/link-2.png">
                              <h3>
                                {{ getSiteDetails.agency_name }} Links
                                <span :class="{'animate' : $route.name === 'links' }">
                              Customizable link that you can create against any URL you visit.
                            </span>
                              </h3>
                            </div>
                          </router-link>
                        </li>

                        <li @click="$refs.create_dropdown_menu.hide(true)"
                            :class="{'active_menu' : $route.name === 'rss' }">
                          <router-link :to="{'name': 'rss'}">
                            <div class="d-flex justify_left">
                              <img class="tag" src="@/assets/img/header/rss-3.png">
                              <h3>
                                RSS Automation
                                <span :class="{'animate' : $route.name === 'rss' }">
                              Add RSS feeds to your campaigns and escalate your brand marketing.
                            </span>
                              </h3>
                            </div>
                          </router-link>
                        </li>

                      </ul>

                    </ul>
                  </transition>
                </b-dropdown>

              </li>

              <li @mouseover="onOver('measure')" @mouseleave="onLeave('measure')" class="m-0 measure-dropdown">
                <b-dropdown ref="measure_dropdown_menu"
                            id="dropdown-left"
                            class="create_dropdown dropdown-menu-left default_style_dropdown"
                            style="padding: 20px 10px !important;"
                            :no-caret="true">
                  <div class="dropdown_header d-flex align-items-center menu_item" slot="button-content"
                       style="padding: 0 5px !important;">
                    <span class="text">Measure</span>

                    <span class="arrow_icon ml-auto">
                                        <i class="fal fa-angle-down ml-2"></i>
                                 </span>

                  </div>
                  <ul class="inner">
                    <ul class="left_ul clear">
                      <li @click="$refs.measure_dropdown_menu.hide(true)"
                          :class="{'active_menu' : $route.name === 'analytics' }">
                        <router-link :to="{'name': 'analytics'}">
                          <div class="d-flex justify_left">
                            <img class="tag" src="@/assets/img/header/line-chart.png">
                            <h3>
                              Analytics
                              <span :class="{'animate' : $route.name === 'analytics' }">
                              Measure matrices such as Clicks, Conversion rate, Unique clicks…
                            </span>
                            </h3>
                          </div>
                        </router-link>
                      </li>

                      <li @click="$refs.measure_dropdown_menu.hide(true)"
                          :class="{'active_menu' : $route.name === 'reports' }">
                        <router-link :to="{'name': 'reports'}">
                          <div class="d-flex justify_left">
                            <img class="tag" src="@/assets/img/header/document.png">
                            <h3>
                              Reports
                              <span :class="{'animate' : $route.name === 'reports' }">
                              {{ getSiteDetails.agency_name }} enables you to automate reporting to all stakeholders.
                            </span>
                            </h3>
                          </div>
                        </router-link>
                      </li>

                    </ul>
                  </ul>
                </b-dropdown>
              </li>

              <li v-if="getProfile.policy.can_view_bio_links" class="simple_title">
                <span class="badge">New!</span>
                <router-link :to="{'name': 'bio_links'}">
                  Bio Links
                  <!--                  <span :class="{'animate' : $route.name === 'bio_links' }"-->
                  <!--                        class="line_animate_h  "></span>-->
                </router-link>
              </li>
            </ul>
          </div>
          <div class="right_side ml-auto" :class="{'activeTourPositionFix' : getGuidedTour.step3}">
            <guided-tour v-if="getGuidedTour.step3"></guided-tour>

            <ul class="d-flex align-items-center">

              <ui-button
                :color="'#ffffff'"
                :bgColor="'#3988fe'"
                :text="`${isBruceCampaign ? 'Upgrade Lifetime Plan' : 'Upgrade Plan'}`"
                :fontSize="'12px'"
                v-if="isBruceCampaign()"
                @click="$bvModal.show('upgrade-ltd-subscription-modal')"
              />

              <li class="ml-2 progress_bar"
                  v-if="getSubscriptionLimits('clicks') && clicksData && clicksData.clicks">
                <b-progress
                  v-tooltip="(clicksData.clicks >= getSubscriptionLimits('clicks')) ? 'Your click limit has been reached.' : ''"
                  v-if="clicksData && clicksData.clicks"
                  :variant="computeLimitsProgressColor(clicksData.clicks,getSubscriptionLimits('clicks'))"
                  :value="clicksData.clicks"
                  :max="getSubscriptionLimits('clicks')" class="mb-1"></b-progress>
                <span v-if="getSubscriptionLimits('clicks') < 10000000">
                  {{
                    clicksData.month
                  }} usage: {{ numberFormatter(computeClickLimits(clicksData.clicks, getSubscriptionLimits('clicks'))) }}
                  / {{ numberFormatter(getSubscriptionLimits('clicks')) }} clicks
                </span>
                <span v-else>
                  {{ clicksData.month }} usage: {{ numberFormatter(clicksData.clicks) }}
                  / Unlimited clicks
                </span>
              </li>

              <li class="ml-2">
                <FrillWidget v-if="!getSiteDetails.isWhiteLabel"></FrillWidget>
              </li>

              <li class="profile_dropdown">

                <b-dropdown right ref="profile_dropdown_menu"
                            class="profile_dropdown dropdown-menu-right default_style_dropdown"
                            :class="{'white_label_menu': !isSuperAdmin()}"
                            :no-caret="true">
                  <div class="dropdown_header d-flex align-items-center" slot="button-content">
                    <span class="text">{{ getProfile.stripped_full_name }}</span>
                    <background-image css_class="rounded_image" :size="33"
                                      :url="profileImage"></background-image>
                    <span class="arrow_icon ml-auto">
                                        <i class="fal fa-angle-down ml-3"></i>
                                 </span>

                  </div>
                  <ul class="inner">
                    <ul class="left_ul clear">
                      <li @click="$refs.profile_dropdown_menu.hide(true)">
                        <router-link :to="{'name': 'profile'}">
                          <i class="fal fa-user" aria-hidden="true"></i> Profile
                        </router-link>

                      </li>
                      <li @click="$refs.profile_dropdown_menu.hide(true)"
                          v-if="getProfile.policy.can_view_billing">
                        <router-link :to="{name:'billing'}">
                          <i class="fal fa-money" aria-hidden="true"></i>
                          Billing
                        </router-link>
                      </li>
                      <li @click="$refs.profile_dropdown_menu.hide(true)"
                          v-if="getProfile.email && getProfile.policy.can_view_team_members">
                        <router-link :to="{'name': 'team'}">
                          <i class="fal fa-users" aria-hidden="true"></i> Team
                        </router-link>

                      </li>
                      <li @click="$refs.profile_dropdown_menu.hide(true)"
                          v-if="false && getProfile.email && isAllowed">
                        <router-link :to="{'name': 'clients'}">
                          <i class="fal fa-users" aria-hidden="true"></i> Clients
                        </router-link>

                      </li>


                      <li @click="$refs.profile_dropdown_menu.hide(true)"
                          v-if="getProfile.policy.can_view_integrations">
                        <router-link :to="{'name': 'integrations'}">
                          <i class="fal fa-cogs" aria-hidden="true"></i> Integrations
                        </router-link>
                      </li>

                      <!-- v-if="getProfile.policy.can_view_deep_links && !getSiteDetails.isWhiteLabel" -->
                      <li @click="$refs.profile_dropdown_menu.hide(true)" class="position-relative">
                        <span class="badge link-new-badge">New!</span>
                        <router-link :to="{'name': 'social-media'}">
                          <i class="far fa-external-link-square" aria-hidden="true"></i> Deep Links
                        </router-link>
                      </li>

                      <li @click="$refs.profile_dropdown_menu.hide(true)"
                          v-if="getProfile.policy.can_view_domains">
                        <router-link :to="{'name': 'custom_domains'}">
                          <i class="fal fa-globe" aria-hidden="true"></i> Custom Domains
                        </router-link>
                      </li>

                      <li @click="$refs.profile_dropdown_menu.hide(true)"
                          v-if="getProfile.policy.can_view_utm">
                        <router-link :to="{name: 'miscellaneous'}">
                          <i class="fal fa-cog"></i>
                          Misc. Settings
                        </router-link>
                      </li>
                      <li @click="$refs.profile_dropdown_menu.hide(true)"
                          v-if="getSubscriptionLimits('whitelabel') && isSuperAdmin()">
                        <router-link :to="{name: 'white-label'}">
                          <i class="far fa-window-restore"></i>
                          Whitelabel
                        </router-link>
                      </li>
                      <li @click="$refs.profile_dropdown_menu.hide(true)"
                          v-if="getProfile.policy.can_view_developer && getSubscriptionLimits('api_access')">
                        <router-link :to="{name: 'apps'}">
                          <i class="fab fa-deviantart"></i>
                          Developer Apps
                        </router-link>
                      </li>

                      <li @click="$refs.profile_dropdown_menu.hide(true)"><a href="/logout"><i
                        class="fal fa-sign-out-alt" aria-hidden="true"></i> Logout</a></li>

                      <div v-if="isSuperAdmin()" class="divider"></div>
                    </ul>

                    <ul v-if="isSuperAdmin()" class="right_ul clear">
                      <li v-if="isWhiteLabelSetup() && isSuperAdmin()" class="mt-2">
                        <a :href="`${computeWhitelabelSwitch().redirect_url}?token=${getJWTToken()}`" target="_self"
                           class="bg-light text-center cursor_pointer rounded-lg px-2 py-3">
                          <i class="fal fa-toggle-on"></i> Switch to {{ computeWhitelabelSwitch().name }}
                        </a>
                      </li>
                      <li @click="$refs.profile_dropdown_menu.hide(true)"
                          :class="[isWhiteLabelSetup() ? 'mt-2' : 'mt-0']" v-if="!getSiteDetails.isWhiteLabel">
                        <a target="_blank"
                           href="https://chrome.google.com/webstore/detail/replug/mofbjpedmdgclbmpgjoboblbppnmgpol"><i
                          class="fab fa-chrome" aria-hidden="true"></i>Chrome Extension</a>
                      </li>

                      <li @click="$refs.profile_dropdown_menu.hide(true)" v-if="!getSiteDetails.isWhiteLabel">
                        <a
                          onclick="Beacon('open');" id="btn-open" href="javascript:;"><i
                          class="fal fa-question-circle" aria-hidden="true"></i> Contact
                          Support</a></li>

                      <li @click="$refs.profile_dropdown_menu.hide(true)" v-if="!getSiteDetails.isWhiteLabel">
                        <a target="_blank"
                           href="https://updates.replug.io/roadmap"><i
                          class="fal fa-location-arrow" aria-hidden="true"></i> Product
                          Roadmap</a></li>

                      <li @click="$refs.profile_dropdown_menu.hide(true)" v-if="!getSiteDetails.isWhiteLabel">
                        <a target="_blank" href="http://docs.replug.io"><i class="fal fa-book"
                                                                           aria-hidden="true"></i>
                          Knowledgebase</a>
                      </li>
                      <li @click="$refs.profile_dropdown_menu.hide(true)"
                          v-if="isSuperAdmin()">
                        <router-link :to="{name: 'refer&earn'}">
                          <i class="fa fa-gift"></i>
                          Refer & Earn
                        </router-link>
                      </li>
                    </ul>

                  </ul>
                </b-dropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<style lang="less">
@import "../assets/less/common";
</style>

<style lang="less">
.manage-dropdown .dropdown-menu.show, .measure-dropdown .dropdown-menu.show {
  -webkit-animation: fadein 500ms;
  -moz-animation: fadein 500ms;
  -ms-animation: fadein 500ms;
  -o-animation: fadein 500ms;
  animation: fadein 500ms;
}

.manage-dropdown .dropdown-menu {
  &:not(.show) {
    -webkit-animation: fadeout 500ms;
    -moz-animation: fadeout 500ms;
    -ms-animation: fadeout 500ms;
    -o-animation: fadeout 500ms;
    animation: fadeout 500ms;
  }
}

@keyframes fadein {
  from {
    margin-top: -20px;
  }
  to {
    margin-top: 8px;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    margin-top: -20px;
  }
  to {
    margin-top: 8px;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    margin-top: -20px;
  }
  to {
    margin-top: 8px;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    margin-top: -20px;
  }
  to {
    margin-top: 8px;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    margin-top: -20px;
  }
  to {
    margin-top: 8px;
  }
}

@keyframes fadeout {
  from {
    margin-top: 8px;
  }
  to {
    margin-top: -20px;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  from {
    margin-top: 8px;
  }
  to {
    margin-top: -20px;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from {
    margin-top: 8px;
  }
  to {
    margin-top: -20px;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  from {
    margin-top: 8px;
  }
  to {
    margin-top: -20px;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
  from {
    margin-top: 8px;
  }
  to {
    margin-top: -20px;
  }
}

.settings-dropdown {
  transition: all .3s ease;
}

.trial_expires_at {
  cursor: pointer;
  background: #f5e46f;
  padding: 5px 6px;
  font-weight: bold;
  margin-right: 10px;
  border-radius: 5px;
}

.trial_expires_at:hover {
  background: #f5f24f;
}

@-moz-document url-prefix() {
  .profile_dropdown .dropdown-menu ul li a {
    font-size: 12px !important;
  }

}

.blur-plan {
  opacity: 0.5;
}
</style>

<script>
import Dropdown from '../ui/Dropdown.vue'
import {mapActions, mapGetters} from 'vuex'
import GuidedTour from '../views/guided-tour/GuidedTour.vue'
import upgrade from '../views/pages/setting/Upgrade.vue'
import UpgradeModal from '../views/pages/setting/billing/UpgradeModal'
import ApplyUTMModal from '../views/pages/setting/utm/ApplyUTMModal'
import PromotionHeaderNotification from './PromotionHeaderNotification'
import BackgroundImage from '../ui/BackgroundImage'
import SocialSharing from 'vue-social-sharing'
import {pricingList} from '@/mixins/pricing_constants'
import FrillWidget from '../ui/FrillWidget'
import {ROLES} from '@/common/constants'
import {allowedMembers} from "@/common/attributes";
import moment from "moment";

export default ({
  'name': 'Header',
  data: function () {
    return {
      isTrail: true,
      isPastDue: true,
      trialText: '',
      'showSettings': false,
      'settingsEnabled': false,
      plan_type: 'monthly',
      pricing_list: pricingList,
      level: 0,
      clicksData: {
        month: '',
        clicks: 0,
        totalClicks: 0
      },
      filteredPlans: [],
      policy: {
        can_view_billing: false
      },
      allowedMembers: allowedMembers,
      ROLES: ROLES,
      subscriptionEffectiveCancelDate: ''
    }
  },
  components: {
    'apply-utm-modal': ApplyUTMModal,
    PromotionHeaderNotification,
    Dropdown,
    GuidedTour,
    upgrade,
    UpgradeModal,
    BackgroundImage,
    SocialSharing,
    FrillWidget,
    'upgrade-subscription-modal': () => import('@/components/UpgradeSubscriptionModal'),
    'upgrade-ltd-subscription-modal': () => import('@/components/UpgradeLTDSubscriptionModal'),
    'announcements-top-banner': () => import('@/ui/ui-kit/AnnouncementsTopBanner')
  },

  created() {
    // progress bar data
    this.clicksData.month = this.getMonthName(new Date())
    if (this.getSubscriptionLimits('clicks')) {
      this.clicksData.totalClicks = this.numberFormatter(this.getSubscriptionLimits('clicks'))
    }
  },
  async mounted() {
    let dateRange = {
      label: ' This Month',
      value: await this.getCurrentMonthDateRange()
    }
    let clicks = await this.fetchTotalClicks(dateRange)
    if (clicks) {
      this.clicksData.clicks = clicks.hits.total
    }

    if (this.getProfile.is_trial_expired) {
      await this.$bvModal.show('upgrade-subscription-modal')
    }

    let date = moment.utc(await this.getProfile.trial_expires_at)
    this.trialText = 'You have ' + moment(date).local().fromNow().toString().slice(2) + ' left of your free trial -'

    // If cancel_effective_at key is present in user object the we display alert message in header
    if (!!this.getProfile.cancellation_effective_at) {

      let cancellationDate = new Date(this.getProfile.cancellation_effective_at);
      let currentDate = new Date();

      this.subscriptionEffectiveCancelDate = `Your account will be deactivated on ${this.computeDate(this.getProfile.cancellation_effective_at)}. Click here to`

      if(cancellationDate < currentDate || this.getProfile.state === 'cancelled') {
        this.subscriptionEffectiveCancelDate = 'Your account has been deactivated due to cancellation. Click here to'
      }

    }

    if (this.getSubscription.fastspring_name && this.getProfile.policy.can_view_billing) {
      this.policy.can_view_billing = true
    }
  },
  computed: {
    ...mapGetters(['getGuidedTour', 'getProfile', 'getSubscription', 'getNotificationsList']),
    profileImage() {
      if (this.getProfile.profile_image) {
        return this.getProfile.profile_image
      }
      return 'https://secure.gravatar.com/avatar/22607c8677eb9b26cb1f568eccc03bff.png?d=mm&r=PG&s=60'
    },
    isAllowed() {
      return this.allowedMembers && this.allowedMembers.includes(this.getProfile.email)
    }
  },
  methods: {

    ...mapActions(['upgradeSubscription', 'fetchTotalClicks']),
    markReadNotification(notification) {
      notification.read = true
      this.$store.dispatch('markRead', notification._id)
    },
    computeDate(date = '') {
      const dateString = new Date(date);
      const options = {year: 'numeric', month: 'short', day: 'numeric'};
      const formattedDate = dateString.toLocaleDateString('en-US', options);

      return formattedDate
    },
    settingsDropdownMenu: function () {
      if (this.showSettings) {
        this.showSettings = false
      } else {
        this.showSettings = true
      }
    },

    onOver(type) {
      if (type === 'manage') {
        this.$refs.create_dropdown_menu.visible = true
      } else if (type === 'measure') {
        this.$refs.measure_dropdown_menu.visible = true
      }
    },
    onLeave(type) {
      if (type === 'manage') {
        this.$refs.create_dropdown_menu.visible = false
      } else if (type === 'measure') {
        this.$refs.measure_dropdown_menu.visible = false
      }
    },
    home() {
      this.$router.push({name: 'dashboard'})
    },
    hideTopBanner(type) {
      if (type === 'trial') {
        this.isTrail = false
        return
      }
      if (type === 'past_due') {
        this.isPastDue = false
        return;
      }

      if (type === 'announcements') {
        this.$store.dispatch('updateNewFeatureAnnouncements')
        return;
      }
    },
    fastspring_name(type) {
      return this.getSubscription && this.getSubscription.fastspring_name && this.getSubscription.fastspring_name.includes(type)
    },
    getJWTToken() {
      return localStorage.getItem('rplg_chrome_logged_jwt_token') ? localStorage.getItem('rplg_chrome_logged_jwt_token') : (localStorage.getItem('rplg_logged_jwt_token') ? localStorage.getItem('rplg_logged_jwt_token') : '')
    }
  }
})
</script>

<style lang="less">
.navigation_component .navigation_component_inner .left_side ul li a.active, .navigation_component .navigation_component_inner .left_side ul li a.active_tab {
  outline: none;
  padding: 18px 10px;
}

.navigation_component .navigation_component_inner .left_side ul li a {
  font-weight: 400;
  padding: 18px 10px;
}

.simple_title:hover, .create_dropdown:hover {

  background-color: rgba(57, 136, 254, 0.05);
}

.create_dropdown:hover .menu_item span {
  color: #2c30b7;
}

.create_dropdown {
  li {
    margin: 0 !important;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: rgba(57, 136, 254, 0.1);
      border-radius: 10px;
    }

    img {
      width: 30px;
      height: 30px;
      margin: 10px
    }

    h3 {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: left;
      color: #1f216e;
      display: inline-block;
      margin-left: 15px;
      cursor: pointer;

      span {
        margin-top: 5px;
        color: #676887;
        font-size: 12px;
        font-weight: 400;
        display: block;
      }
    }
  }
}

.active_menu {
  background-color: rgba(57, 136, 254, 0.1) !important;
  border-radius: 10px !important;
}

.manage-dropdown {
  .dropdown-menu {
    margin-left: 0 !important;
    left: -25px !important;
  }
}

.measure-dropdown {
  .dropdown-menu {
    margin-left: 0 !important;
    left: -25px !important;
  }
}

.create_dropdown .dropdown-menu {
  width: 360px !important;
  outline: none !important;
}

.measure-dropdown .create_dropdown .inner {
  padding: 15px 15px !important;
}

.manage-dropdown .create_dropdown .inner {
  height: 360px !important;
  max-height: 360px !important;
  padding: 15px 15px !important;

  transition: 0.5s;
}

@-moz-document url-prefix() {
  .manage-dropdown .create_dropdown .inner {
    height: 370px !important;
    max-height: 370px !important;
  }
}

.create_dropdown .menu_item .text {
  display: block;
  font-size: 14px;
  /*font-weight: 600;*/
  color: #333;
  font-family: 'Nunito Sans', sans-serif;
}

.create_dropdown .menu_item:hover .text {
  color: #2c30b7;
}

.create_dropdown a {
  padding: 0 !important;
}

.create_dropdown_text {
  display: inline-block;
}

.create_dropdown_text span {
  color: #989eb5;
  font-size: 13px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
}

.active_tab {
  color: #070cad !important;
  font-weight: 500 !important;
}

.line_animate_h_dropdown {
  right: auto;
  width: 100%;
  position: relative;
  display: block;
  margin-left: 0;
}

.manage-dropdown .default_style_dropdown .dropdown-menu .inner,
.measure-dropdown .default_style_dropdown .dropdown-menu .inner,
.profile_dropdown .default_style_dropdown .dropdown-menu .inner {
  overflow: hidden !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.manage-dropdown .default_style_dropdown.dropdown-menu-left .dropdown-menu:before,
.manage-dropdown .default_style_dropdown.dropdown-menu-left .dropdown-menu:after,
.measure-dropdown .default_style_dropdown.dropdown-menu-left .dropdown-menu:before,
.measure-dropdown .default_style_dropdown.dropdown-menu-left .dropdown-menu:after {
  left: 30px !important;
}

.progress_bar {
  width: 210px;
  margin-top: 10px;

  .progress {
    height: 0.5rem !important;
    width: 140px !important;
  }

  span {
    color: #828282;
    font-size: 0.675rem;
  }
}

.heading_x {
  h2 {
    margin-top: 10px;
    font-size: 2rem !important;
    line-height: 1.3;
    color: #1f216e !important;
  }

  .mt-3 {
    color: #1f216e !important;
    margin-top: 10px;
    font-size: 1rem;
    font-weight: lighter;
    opacity: 0.7;
  }
}

.white_label_menu {
  .dropdown-menu {
    min-width: 250px !important;

    .left_ul {
      width: 100% !important;
    }
  }
}
</style>
